


























































import Hacienda from "@/entidades/Plantacion/Hacienda";
import HaciendaItem from "@/entidades/Plantacion/HaciendaItem";
import AgregarLote from "@/components/Maestro/Hacienda/AgregarLote.vue";
import DetalleEditarLote from "@/components/Maestro/Hacienda/EditarLote.vue";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import HaciendaItemLote from "@/entidades/Plantacion/HaciendaItemLote";

@Component({
  components: {
    AgregarLote,
    DetalleEditarLote,
  },
})
export default class extends Vue {
  @Prop()
  hacienda!: Hacienda;

  @Prop()
  haciendaItem!: HaciendaItem;

  @Prop()
  haciendas!: Array<Hacienda>;

  //propiedades locales
  ordernInterna: string = "";
  anioCultivo: number = 0;
  descripcion: string = "";
  haciendaItemId: number = 0;
  checkConfiguracion: boolean = false;
  haciendaId: number = 0;

  cambiarHacienda(value: number) {
    var hac = this.haciendas.find((t) => t.haciendaId == value);
    if (hac != undefined) {
      this.haciendaId = hac.haciendaId;
      this.descripcion = hac.nombre;
    }
  }
  agregar() {
    let haciendaItem = new HaciendaItem(
      this.haciendaId,
      this.haciendaItemId,
      this.descripcion,
      this.ordernInterna,
      Number(this.anioCultivo)
    );

    //mayor que cero vamos agregar los lotes para esta orden interna
    this.$emit("agregar", haciendaItem);
  }


  get ActualizarTextoBoton()
  {
      if(this.haciendaItem != null)
      {
        if(this.haciendaItem.haciendaItemId! > 0)
          return "EDITAR";
      }
      else 
        return "AGREGAR";
  }

  mounted() {
    if (this.hacienda != undefined) {
      this.haciendaId = this.hacienda.haciendaId;
      this.descripcion = this.hacienda.nombre;
    }
    if (this.haciendaItem != null) {
      this.haciendaItemId = this.haciendaItem.haciendaItemId!;
      this.haciendaId = this.haciendaItem.haciendaId!;
      this.descripcion = this.haciendaItem.descripcion!;
    }
  }
}
