


















































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import HaciendaItem from "@/entidades/Plantacion/HaciendaItem";
import HaciendaItemLote from "@/entidades/Plantacion/HaciendaItemLote";

@Component
export default class extends Vue {
  @Prop()
  haciendaItemLote!: HaciendaItemLote;

  @Prop()
  haciendaItem!: HaciendaItem;

  @Prop()
  haciendaItems!: Array<HaciendaItem>;

  //local properties
  descripcion?: string = "";
  haciendaItemId?: number = 0;


  cambiarHacienda(value: number) {
    var hac = this.haciendaItems.find((t) => t.haciendaItemId == value);
    if (hac != undefined) {
      this.haciendaItemId = hac.haciendaItemId;
      this.descripcion = hac.descripcion;
    }
  }
  agregarLote() {
    this.$emit("agregarItemLote", this.haciendaItemLote);
  }
}
