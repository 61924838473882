import HaciendaItem from './HaciendaItem';
import ResponseCustom from '../ResponseCustom';
import ResponseGenerico from '../ResponseGenerico';
import {Obtener,Save,Editar} from '../../Generico/Conexion';
import {HaciendaLote,HaciendaLoteCustomer} from './HaciendaLote';
import {mainAxios} from '@/plugins/axios'
export default class Hacienda
{

    haciendaId:number =0;
    descripcion:string ="";
    nombre:string ="";
    estado:boolean =true;
    ordenInterna:string ="";
    latitud:number=0;
    longitud:number=0;
    zonaClimaticaId:number = 0;
    zonaGeograficaId:number = 0;
    zonaId:number=0;
    zona:string="";
    haciendaItem:Array<HaciendaItem> =[];
    lotes:Array<HaciendaLoteCustomer> = [];
    haciendaLote:Array<HaciendaLote> = [];
    zonaClimatica:string="";
    zonaGeografica:string="";

    static async Obtener():Promise<ResponseCustom<Hacienda>>
    {

      return await Obtener<Hacienda>("Hacienda/Obtener",mainAxios);
    }

    static async Agregar(hacienda:Hacienda):Promise<ResponseGenerico>
    {
        return await Save("Hacienda/Agregar",hacienda,mainAxios);
    }

    static async EditarEstado(hacienda:Hacienda):Promise<ResponseGenerico>
    {
        return await Editar("Hacienda/EditarEstado",hacienda,mainAxios);
    }
    static async AgregarLoteHacienda(hacienda:HaciendaLote):Promise<ResponseGenerico>
    {
        return await Save("Hacienda/AgregarLoteHacienda",hacienda,mainAxios);
    }

    static async ObtenerHaciendaLote(haciendaId:number)
    {
        return await Obtener<HaciendaLote>(`Hacienda/obtenerLoteHacienda/${haciendaId}`,mainAxios);
    }
    static async ObtenerLoteIem(haciendaId:number)
    {
      return await Obtener<HaciendaLote>(`loteitem/obtenerloteitem/${haciendaId}`,mainAxios);
    }
}
