import {Save,Obtener,Editar} from '@/Generico/Conexion';
import { mainAxios } from '@/plugins/axios';
import ResponseGenerico from '../ResponseGenerico';
class HaciendaMapa
{
    haciendaId:number =0;
    descripcion:string ="";
    latitud:number =0;
    longitud:number=0;
    lotes:Array<LoteMapa> =[]
}

class LoteMapa
{
    loteId:number =0;
    descripcion:string="";
    longitud:number =0;
    latitud:number=0;
}

async function ObtenerHaciendaMapa()
{
    return await Obtener<HaciendaMapa>("hacienda/ObtenerHaciendaMapa",mainAxios);
}

export 
{
  HaciendaMapa,
  ObtenerHaciendaMapa,
  LoteMapa
}