import ResponseCustom from '../ResponseCustom';
import ResponseGenerico from '../ResponseGenerico';
import {Obtener,Save,Editar} from '../../Generico/Conexion';
import { mainAxios } from '@/plugins/axios'
export default class ZonaClimatica 
{
    zonaClimaticaId: number =0;
    descripcion:string ="";
    estado:boolean = true;

     static async Obtener():Promise<ResponseCustom<ZonaClimatica>>
    {
      return await Obtener<ZonaClimatica>("ZonaClimatica/Obtener",mainAxios);
    }
}