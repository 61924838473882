import ResponseCustom from '../ResponseCustom';
import {Obtener,Save,Editar} from '../../Generico/Conexion';
import { mainAxios } from '@/plugins/axios'
export default class ZonaGreogrfica
{
    zonaGeograficaId : number = 0;
    descripcion:string = "";
    estado:boolean = true;

    static async Obtener():Promise<ResponseCustom<ZonaGreogrfica>>
    {
      return await Obtener<ZonaGreogrfica>("ZonaGreografica/Obtener",mainAxios);
    }
}