







































import {Vue,Prop,Component} from  'vue-property-decorator';
import FiltroHacienda from '@/components/Hacienda/FiltroHaciendaLote.vue';
//@ts-ignore
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {HaciendaMapa,ObtenerHaciendaMapa,LoteMapa} from '@/entidades/Plantacion/HaciendaMapa';
@Component
export default class MapaView extends Vue
{
    @Prop({type:String,required:true})
    lat!:String
    @Prop({type:String,required:true})
    long!:String
    @Prop({type:String,required:true})
    name!:String

    listadoHacienda:Array<HaciendaMapa>=[];
    listadoLote:Array<LoteMapa>=[];
    haciendaId:number=0;
    loteId:number=0;
    markersArray:Array<any>=[];
    map:any= null
    descripcion:string="";
    latitud:string="";
    longitud:string="";

    async ObtenerHaciendaItem()
    {
        try
        {
            let response = await ObtenerHaciendaMapa();
            if(response.isSuccess == true)
            {
                this.listadoHacienda = response.isResult;
            }
        }
        catch(error)
        {

        }
    }
    obtenerLote()
    {
        let hacienda = this.listadoHacienda.find(c=>c.haciendaId == this.haciendaId);
        if(hacienda != undefined)
        {
             this.listadoLote = hacienda.lotes;
        }
       
    }
    ObtenerMarcacion()
   {
       let listadoFiltrado= [];
       if(this.haciendaId >0)
       {
            listadoFiltrado = this.listadoHacienda.filter(c=>c.haciendaId == this.haciendaId);
       }
       else
       {
           listadoFiltrado = this.listadoHacienda;
       }

        if(this.markersArray.length > 0)
        {
            this.markersArray.forEach(t=> {
                t.remove();
            })
        }
       listadoFiltrado.forEach(c=> {
           c.lotes.forEach(t=> {
               var mark = new mapboxgl.Marker({color:'black'})
                    .setLngLat([t.longitud, t.latitud])
                    .setPopup(new mapboxgl.Popup({offset:25})
                    .setHTML(`<h3> ${t.descripcion} </h3>`)
                    ).addTo(this.map);
                
                 mark.getElement().addEventListener('click', () =>{
                     //console.log('le di click al marcado', mark);
                     this.RegistrarInformacion(mark,c.haciendaId,t.loteId);
                 });

                  mark.getElement().addEventListener('mouseover', () =>{
                     this.descripcion = t.descripcion;
                     this.longitud  = String(t.longitud);
                     this.latitud = String(t.latitud);

                 });

                this.markersArray.push(mark);
           })
           
       });
   }
   RegistrarInformacion(marcado:any,haciendaId:number,loteId:number)
   {
       console.log(marcado);
       console.log(haciendaId);
       console.log(loteId);
      
   }
 
    async mounted()
    {
        await this.ObtenerHaciendaItem();
        mapboxgl.accessToken = 'pk.eyJ1Ijoic2dhbGFyemEiLCJhIjoiY2txZmt3NDQxMTZoejJvbnpoaXMxZHN3OSJ9.J91cE1PchN1XaPo6loqCog';
        this.map  = new mapboxgl.Map({
            container : 'mapa',
            style : 'mapbox://styles/mapbox/outdoors-v11',
            center: [this.long, this.lat ],
            zoom: 8
        });
        var mark = new mapboxgl.Marker({color:'red'})
                    .setLngLat([this.long, this.lat])
                    .setPopup(new mapboxgl.Popup({offset:25})
                    .setHTML(`<h3> ${this.name} </h3>`)
                    ).addTo(this.map);
        this.markersArray.push(mark);
     // Add zoom and rotation controls to the map.
        this.map.addControl(new mapboxgl.NavigationControl());
        this.map.addControl(new mapboxgl.FullscreenControl());
        this.map.getCanvas().style.cursor= 'pointer';
    }
  
}
