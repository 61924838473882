













import HaciendaItem from '@/entidades/Plantacion/HaciendaItem'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
@Component
export default class extends Vue
{
    @Prop({type:Array})
    items!:Array<HaciendaItem>;



    header:{text:string,sortable:boolean,value:string}[] =[
            {text:"Descripcion",sortable:true,value:'descripcion'},
            {text:"Año Cultivo",sortable:true,value:'anioCultivo'},
            {text:"Orden Interna",sortable:true,value:'ordenInterna'},
            {text:"Eliminar",sortable:true,value:'eliminar'}]
            
    //Metodos

    eliminar(item:HaciendaItem)
    {
        this.$emit('eliminar',item);
    }
    //Compute

    get listado()
    {
        return this.items;
    }
    
}
