


















































import Vue from 'vue'
import {  Prop ,Component } from 'vue-property-decorator'

@Component
export default class extends Vue
{
    //variable internas 
    showLocal:Boolean =false;
    //PROP
    @Prop({type:Boolean,required:true})
    show!:Boolean

    @Prop({type:Object,required:true})
    informacion!:Object

    @Prop({type:Boolean,required:true})
    isNew!:Boolean

    @Prop({type:Array})
    listado!:[]

    get updateShow(){
        this.showLocal = this.show;
        return this.show;
    }
    set updateShow(value:Boolean)
    {
        this.showLocal = value;
    }

    get titleAction(){
        if(this.isNew == true)
        {
            return "GUARDAR";
        }
        else {
            return "EDITAR";
        }
    }

    

    ///ciclo de vida
    mounted()
    {
    }
    
}
