
































































import MapaView from "@/views/Forestal/Plantacion/Mapa.vue";
import Hacienda from "@/entidades/Plantacion/Hacienda";
import HaciendaItem from "@/entidades/Plantacion/HaciendaItem";
import Vue from "vue";
import { Prop, Component, Watch } from "vue-property-decorator";
import AgregarItem from "./AgregarItem.vue";
import TablaSimple from "./TablaSimple.vue";
import TablaEliminar from "@/components/General/TablaDelete.vue";
import { HaciendaLote } from "@/entidades/Plantacion/HaciendaLote";
import ZonaGreogrfica from "@/entidades/Plantacion/ZonaGeografica";
import ZonaClimatica from "@/entidades/Plantacion/ZonaClimatica";
@Component({
  components: {
    MapaView,
    AgregarItem,
    TablaSimple,
    TablaEliminar,
  },
})
export default class extends Vue {
  @Prop({ type: Boolean })
  show!: boolean;
  @Prop({ type: Hacienda })
  hacienda!: Hacienda;

  listadoZonaGeografica: Array<ZonaGreogrfica> = [];
  listadoZonaClimatica: Array<ZonaClimatica> = [];

  guardar() {
    this.$emit("guardar");
  }
  
  async ObtenerZonas() {
    try {
      //vamos a obtener la zona climatica
      let rsp = await ZonaClimatica.Obtener();
      if (rsp.isSuccess == true) this.listadoZonaClimatica = rsp.isResult;
      let rsp2 = await ZonaGreogrfica.Obtener();
      if (rsp2.isSuccess == true) this.listadoZonaGeografica = rsp2.isResult;
    } catch (error) {
      console.log(error);
    }
  }

 
  get modal() {
    let modalTmp = this.show;
    return modalTmp;
  }

  mounted() {
    this.ObtenerZonas();
  }
}
