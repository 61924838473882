












































import Lotes from '@/entidades/Plantacion/Lotes'
import Vue from 'vue'
import {Component,Prop} from 'vue-property-decorator'

@Component
export default class extends  Vue
{

    @Prop()
    listadoLote!:Array<Lotes>

    header:{text:string,sortable:boolean,value:string}[] =[
            {text:"Descripcion",sortable:true,value:'descripcion'},
            {text:"Hectareas",sortable:true,value:'hectareas'},
            {text:"Latitud",sortable:true,value:'latitud'},
            {text:"Longitud",sortable:true,value:'longitud'},
    ]
    
}
